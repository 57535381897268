import React from 'react'
import Icon from '../icon'

import StatusInfo from './statusInfo'
import DateLabel from '../date/label'
import DayTimeLabel from '../date/dayTime'
import Currency from '../currency'

const ClientStatus = ({
  appointments = [],
  isInfoComplete = false,
  payment = {},
}) => (
  <div className="client-status">
    <ol className="status__appointment">
      {['1', '2', '3'].map(number => {
        const appointment = appointments.find(
          appt => appt.appointment === number
        )

        return appointment ? (
          <li
            key={number}
            data-status={
              appointment.complete === '1' ? 'complete' : 'scheduled'
            }
          >
            <StatusInfo
              info={
                <div className="layout--centered">
                  <DateLabel date={appointment.date.start} />
                  <DayTimeLabel date={appointment.date} />
                </div>
              }
            >
              <Icon
                icon="appt"
                theme={appointment.appointment.toString()}
                value={appointment.appointment}
              />
            </StatusInfo>
          </li>
        ) : (
          <li key={number} data-status="none">
            <Icon icon="appt" theme="disabled" value={number} />
          </li>
        )
      })}
    </ol>

    <div className="status__client-info" data-status="complete">
      {isInfoComplete ? (
        <Icon icon="client" theme="secondary" modifier="check" />
      ) : (
        <Icon icon="client" theme="disabled" />
      )}
    </div>
  </div>
)

export default ClientStatus
